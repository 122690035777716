import React, { useEffect, useState, useContext } from "react";
import Layout from "../components/layout";
import Button from "../components/Button";
import api from "../api.js";
import { AuthContext } from "../context/GlobalContextProvider";
import { useForm } from "react-hook-form";
import axios from "axios";

function login() {
  const context = useContext(AuthContext)
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    await context.handleLogin(data)
    console.log(context)
  }

  useEffect(() => {
    axios.get('https://services.bivver.com.br:8443')
      .then(res => console.log(res.data))
      .catch(error => console.log(error))
  },[])

  return (
    <Layout>
      <div className="text-justify my-12">
        <form onSubmit={handleSubmit(onSubmit)} className="w-1/5 mx-auto">
          <div>
            <input 
              className="my-5"
              placeholder="Usuário"
              name="username"
              ref={register}
            />
            <input 
              className="my-5"
              placeholder="Senha"
              name="password"
              ref={register}
            />
            <Button className="w-full my-5" type={"submit"}>
              Login
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default login;
